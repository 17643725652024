//import './wdyr';
import 'react-app-polyfill/stable';
import {lazy, Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import scubeBuildVersionJson from './buildVersion.json';

declare global {
    interface Window {
        appType: string;
        isDevelopment: boolean;
        isBulkInvitation: boolean;
        allowedCountry: boolean;
        isAllowedPhysicalOnCurrentMarket: boolean;
        version: string;
        cacheVersion: string;
        masterVersion: string;
        apVersion: string;

        lastTrackedInnerWidth: number | undefined;
        lastTrackedInnerHeight: number | undefined;
    }
}

console.log('0.2.8');
console.log(scubeBuildVersionJson.scubeBuildVersion);
console.log(window.version);
console.log(window.cacheVersion);
console.log(window.masterVersion);
console.log(decodeURI(window.apVersion));

function BootAppLoader() {
    return (
        <span className="ouro ouro3">
          <span className="left">
            <span className="anim"></span>
          </span>
          <span className="right">
            <span className="anim"></span>
          </span>
          <svg className="logo" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
            <path d="M18.94,3.24V20.71H15.23l-.4-1.56a8.51,8.51,0,0,1-5.66,2.08A9,9,0,0,1,0,12a9,9,0,0,1,9.17-9.2,8.51,8.51,0,0,1,5.74,2.14l.5-1.67ZM14.31,12A4.64,4.64,0,0,0,9.55,7.18,4.65,4.65,0,0,0,4.79,12a4.65,4.65,0,0,0,4.76,4.79A4.64,4.64,0,0,0,14.31,12Zm5.45-4.49H24V3.24H19.76Z"></path>
          </svg>
        </span>
    );
}

const AllApps = lazy(() => import(/* webpackChunkName: "AllApps" */ './allApps'));
// ReactDOM.render(
//   <Suspense fallback={<BootAppLoader />}>
//     <AllApps />
//   </Suspense>,
//   document.getElementById('root')
// );

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Suspense fallback={<BootAppLoader/>}>
    <AllApps/>
</Suspense>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
